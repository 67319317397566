<template>
  <v-app>
    <v-app-bar app color="blue-dark" dark>
      <v-toolbar-title>FFME</v-toolbar-title>
    </v-app-bar>
    <div style="max-height: 100vh; overflow-y: scroll;">
      <v-container style="padding-bottom: 100px;">
        <router-view></router-view>
      </v-container>
    </div>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },

  created() {},
};
</script>
